<template>
	<section class="join-member">
		<div class="max-width-1000">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/join">加入金枝</el-breadcrumb-item>
				<el-breadcrumb-item>金粉會員</el-breadcrumb-item>
			</el-breadcrumb>
			<br>
			<article>
				<h2 class="theme_text">會員權益</h2>
				<hr>
				<br>
				<ol>
					<li>優先得知金枝演社的演出及活動訊息（手機簡訊、電子報）。</li>
					<li>可在金枝演社節目演出前獲得金枝寄發的精美DM。</li>
					<li>購買金枝主辦之演出節目票券，基本享有9折優惠，若有特別折扣則再降低，比一般觀眾享有更多優惠。</li>
					<li>參加金枝主辦之表演／推廣課程享有特別優惠。</li>
				</ol>
			</article>
			<br>
			<article>
				<h2 class="theme_text">我要加入</h2>
				<hr>
				<br>
				<member-form ref="ruleForm" />
				<br>
				<div class="center">
					<el-button type="theme" round @click="handleRigister()">立即加入</el-button>
				</div>
			</article>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	components: {
		MemberForm: () => import("@/components/join/member-form")
	},
	computed: {
		...mapState("auth", ["_auth"])
	},
	methods: {
		...mapActions("auth", ["_register", "_logout"]),
		async handleRigister() {
			try {
				const { message } = await this._register(await this.$refs["ruleForm"].validate());
				this.$alert(message, {
					type: "success",
					dangerouslyUseHTMLString: true
				}).then(async () => {
					if (this._auth) await this._logout();
					this.$router.push("/login");
				});
			} catch (error) {
				this.$message.error(error);
			}
		},
	},
	created() {
		if (this._auth) {
			const { roles } = this._auth;
			if (roles.includes("member")) {
				this.$confirm(
					`您已加入金粉會員，欲享有更多優惠請加入<br><a href="/join/donor" style="color:#a98a4c;"><u>金枝椅仔會</u></a>。`,
					{
						type: "warning",
						dangerouslyUseHTMLString: true,
						confirmButtonText: "前往了解",
						cancelButtonText: "知道了"
					}
				).then(() => {
					this.$router.push("/join/donor");
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.join-member {
	padding: 50px 5%;
}
</style>